@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.container {
  margin: 20px;
  background: #f4f4f4 !important;
}

.discover {
  width: 100%;
  background: #f4f4f4 !important;
}

.sktcontainer {
  padding: 5px 10px;
}
