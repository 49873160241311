@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.dashboardcontainer {
  padding: 24px 16px;
}

.ant-pro-table-list-toolbar-container {
  padding-top: 16px;
}

.infotitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;

  &:hover {
    color: #0a5f7a;
  }
}

.infosubtitle {
  font-size: 13px;
  font-weight: 500;
  color: rgb(0 0 0 / 85%);
}

.infodesc {
  font-size: 13px;
  color: rgb(0 0 0 / 85%);
}

.infocontainer {
  width: 250px !important;
}

.avatar {
  color: @primary-color !important;
  vertical-align: top;
  margin: auto 8px;
  background: #e7f0f2 !important;
}
