@import (reference) 'antd/lib/style/themes/default.less';
@import 'antd/dist/antd.less';
@import 'main-variables.less';
@import (reference) 'antd/lib/drawer/style/drawer.less';

/** *** Global Overrides *** **/
@tbl-pagination-item-border-radius: 0px;
@segmented-border-radius: 0px;

/**  Table Pagination Items  **/
.@{pagination-prefix-cls} {
  &-item {
    border-radius: @tbl-pagination-item-border-radius;
  }
}

/**  Segmented  **/
.@{segmented-prefix-cls} {
  border-radius: @segmented-border-radius;

  &-item {
    border-radius: @segmented-border-radius;
  }

  &-thumb {
    border-radius: @segmented-border-radius;
  }
}
