@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.container {
  margin: 0;
  padding: 0 18px;
  background-color: white;
}

.table {
  height: 100%;
  overflow: auto;
}

.cardwrapper {
  padding: 24px;
  background-color: #f5f5f5;
}
