/* stylelint-disable selector-class-pattern */
@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.page-header {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.baseView {
  display: flex;

  :global {
    .ant-legacy-form-item .ant-legacy-form-item-control-wrapper {
      width: 100%;
    }
  }

  .left {
    min-width: 424px;
    max-width: 700px;
    height: 90vh;
  }

  .right {
    flex: 1;
    padding-left: 104px;

    .avatar_title {
      color: black;
      font-size: 14px;
    }

    .avatar {
      width: 144px;
      height: 144px;
      margin-bottom: 12px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .button_view {
      width: 144px;
      text-align: center;
    }
  }
}

@media screen and (max-width: @screen-xl) {
  .baseView {
    flex-direction: column-reverse;

    .right {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 700px;
      padding: 20px;

      .avatar_title {
        display: none;
      }
    }
  }
}
