/* stylelint-disable selector-class-pattern */
@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.popover {
  position: relative;
  width: 336px;
}

.icon {
  padding: 4px;
  vertical-align: middle;
}

.badge {
  font-size: 16px;
}

.tabs {
  :global {
    .ant-tabs-nav-list {
      margin: auto;
    }

    .ant-tabs-nav-scroll {
      text-align: center;
    }

    .ant-tabs-nav {
      margin-bottom: 0;
    }
  }
}

.badgestyle {
  margin: 0 !important;
  color: black !important;
}
