@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.container {
  padding: 0;
  display: flex;
}

.lefttable {
  width: 100%;
}

.righttable {
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  width: 320px;
}

.table {
  overflow: auto;
  padding: '0px 40px';
}

.titlecontainer {
  width: 100%;
}

.site-page-header-ghost-wrapper {
  padding: 24px;
  background-color: #f5f5f5;
}

.cardwrapper {
  padding: 24px;
  background-color: #f5f5f5;
}

.tracecontainer {
  width: 100%;
  padding: 5px 15px !important;
  padding-right: 30px !important;
  padding-bottom: 22px !important;
}

.emptycontainer {
  padding: 17vh 0;
}
