@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.container {
  margin: '0px 20px';
  display: flex;
}

.lefttable {
  width: 100%;
}

.righttable {
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  width: 320px;
}

.table {
  overflow: auto;
  padding: '0px 40px';
}

.titlecontainer {
  width: 100%;
}

.site-page-header-ghost-wrapper {
  padding: 24px;
  background-color: #f5f5f5;
}

.cardwrapper {
  padding: 24px;
  background-color: #f5f5f5;
}

.tracecontainer {
  width: 100%;
  padding: 5px 15px !important;
  padding-bottom: 22px !important;
}

.summarytitlestyles {
  padding: 5px 15px !important;
  padding-bottom: 0 !important;
  padding-right: 30px !important;
}

.summarycontainer {
  padding: 0 24px;
  display: flex;
  width: 100%;
}

.summary {
  width: 80%;
  margin-right: 5px;
}

.anchors {
  width: 19%;
  padding: 0 10px;
  float: right !important;
}

.tablecolumn {
  background: #fafafa;
}
