@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.alerticon {
  color: red !important;
}

.emailtag {
  margin-right: 3px !important;
  border-radius: 15px !important;
}
