/* stylelint-disable no-descending-specificity */
@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

@pro-header-hover-bg: rgba(0, 0, 0, 0.025);

.menu {
  :global {
    .ant-dropdown-menu-item {
      min-width: 160px;
    }
  }

  border-radius: 2px !important; // TODO: check other way to do it

  .profile {
    :global(.ant-dropdown-menu-title-content) {
      display: flex;
      flex-direction: column;
    }

    span:nth-child(2) {
      margin-top: 8px;
    }
  }

  .signout {
    color: @primary-color;
  }
}

.dark {
  .action {
    &:hover {
      background: #252a3d;
    }

    &:global(.opened) {
      background: #252a3d;
    }
  }
}

.right {
  display: flex;
  float: right;
  margin-left: auto;
  height: 48px;
  overflow: hidden;

  button {
    height: inherit;
  }

  .action {
    display: flex;
    align-items: center;
    transition: all 0.3s;

    > span {
      vertical-align: middle;
    }

    &:hover {
      background: @pro-header-hover-bg;
    }

    &:global(.opened) {
      background: @pro-header-hover-bg;
    }
  }

  .search {
    padding: 0 12px;

    &:hover {
      background: transparent;
    }
  }

  .account {
    .avatar {
      color: @primary-color;
      vertical-align: top;
      margin: auto 8px;
      background: rgb(255 255 255 / 85%);
    }
  }
}

.universalsearch {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: rgb(255 255 255 / 10%);
  }

  .input {
    width: 0;
    min-width: 0;
    overflow: hidden;
    background: transparent;
    border-radius: 2px;
    transition: width 0.3s, margin-left 0.3s;

    :global(.ant-select-selection) {
      background: transparent;
    }

    input {
      box-shadow: none !important;
    }

    &.show {
      width: 210px;
      margin-left: 8px;
    }
  }
}

@media only screen and (max-width: @screen-md) {
  :global(.ant-divider-vertical) {
    vertical-align: unset;
  }

  .right {
    position: absolute;
    top: 0;
    right: 12px;

    .search {
      display: none;
    }
  }
}
