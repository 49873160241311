@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.container {
  margin: 20px;
  background: #f4f4f4 !important;
}

.endorsers {
  width: 100%;
  background: #f4f4f4 !important;
}

.page-header {
  padding-left: 0 !important;
  padding-top: 0 !important;
}
