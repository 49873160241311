.cnt {
  // text-align: center;
  background-color: white;
  padding: 40px;
  position: absolute;
  inset: 0;
  margin: auto;
  height: 460px;
  width: 520px;
  box-shadow: 0 1px 16px 0 rgb(0 0 0 / 10%);
  border-radius: 2px;
}

.imgcontainer {
  margin-bottom: 8px;
}

.subtitle {
  margin-top: 50px;
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}

.description {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
  line-height: 30px;
}

.firstname {
  margin: 24px 0;
}

.lastname {
  margin: 24px 0;
}

.buttoncolor {
  color: #0a5f7a;
  border-color: #bcd530;
  background: #bcd530;
  text-shadow: 0 0;
  box-shadow: 0 0;
}
