@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.diagramcontainer {
  display: grid !important;
  justify-content: center !important;
  align-items: center !important;
}

.pdr18 {
  padding-right: 18px !important;
}
