/* stylelint-disable selector-class-pattern */
@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.title {
  padding: 4px 2px;
}

.headerText {
  font-size: 14px;
  font-weight: 400;
  margin: 5px 0 15px;
}

.placeholder {
  opacity: 0.3 !important;
  font-weight: 400 !important;
}

.backButton {
  display: inline-block;
  width: 24px;
}

.backButton:hover {
  cursor: pointer;
}

.backIcon svg {
  color: #00000073;
}
