@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.infotitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.infosubtitle {
  font-size: 14px;
  font-weight: 500;
  color: rgb(0 0 0 / 85%);
}

.infodatebold {
  font-size: 14px;
  font-weight: 500;
  color: rgb(0 0 0 / 85%);
}

.infoeventsubtitle {
  font-size: 14px;
  font-weight: 500;
  color: rgb(0 0 0 / 85%);
  cursor: pointer;
}

.infodesc {
  font-size: 14px;
  color: rgb(0 0 0 / 85%);
}

.infodate {
  font-size: 14px;
  color: rgb(0 0 0 / 85%);
  position: absolute;
  right: 10px;
  bottom: 60px;
}

.infocontainer {
  width: 250px;
}

.headerdivider {
  margin-top: 5px !important;
  margin-bottom: 7px !important;
}

.event {
  cursor: pointer;
  width: 100%;
  padding: 5px;

  .eventtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &:hover {
    background-color: @background-color-light;

    .eventtitle {
      .infosubtitle {
        color: #0a5f7a;
      }
    }
  }
}

.eventscontainer {
  width: 100%;
  max-height: 230px;
}

.jsonbox {
  height: 47vh;
  overflow: 'auto';
}

.mrt5 {
  margin-top: 5px;
}

.mrt10 {
  margin-top: 10px;
}

.mrb10 {
  margin-bottom: 10px;
}

.cardtitle {
  height: 15px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.digramtext {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 30px;
}

.diagramicon {
  height: 20px !important;
  width: 20px !important;
}

.diagramcard {
  width: 100%;
  margin: 8px 0 10px;
}

.detailsmodal {
  :global(.ant-modal-body) {
    height: 70vh;
    overflow: auto;
  }
}

.titleflex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.infowindowheader {
  width: 250px !important;
}
