@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.info {
  margin-left: @margin-xs;
}

.container {
  margin-top: 10px;
  padding-top: 10px;
}

.headertext {
  font-size: 14px;
  font-weight: 400;
  margin: 5px 0 15px;
}

.formtext {
  font-size: 14px;
  font-weight: 400;
  margin: 8px 0 12px;
}

.searchfields {
  margin: 12px 0 16px;
}
