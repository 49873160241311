.modal {
  height: 60vh;
}

.changerolemodal {
  height: 60vh;
}

.emailtag {
  background: #f5f5f5 !important;
  border: 1px solid #f0f0f0 !important;
  height: 24px;
}
