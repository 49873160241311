.filterdropdown {
  padding: 8px;
}

.filterinput {
  display: block !important;
  margin-bottom: 8px !important;
  border-radius: 20px !important;
}

.filterbtn {
  width: 90px;
}

.filterresetbtn {
  width: 90px;
  color: rgb(10 95 122);
  border: 1px solid rgb(10 95 122);
}
