/* stylelint-disable selector-class-pattern */
@import 'themes/main.less'; // default application theme

body {
  margin: 0;
}

html,
body,
#root {
  height: 100%;
  background-color: #f4f4f4;
}

.ant-layout {
  min-height: 100vh;
}

.ant-pro-sider.ant-layout-sider.ant-pro-sider-fixed {
  left: unset;
}

canvas {
  display: block;
}

ul,
ol {
  list-style: none;
}

@media (max-width: @screen-xs) {
  .ant-table {
    width: 100%;
    overflow-x: auto;

    &-thead > tr,
    &-tbody > tr {
      > th,
      > td {
        white-space: pre;

        > span {
          display: block;
        }
      }
    }
  }
}

.ant-table.ant-table-small {
  border-radius: 0;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  // tr:last-child {
  //   border-bottom-right-radius: 6px;
  //   border-bottom-left-radius: 6px;
  // }

  .ant-table-expanded-row-fixed {
    padding: 0;

    .ant-table-cell {
      background: @expanded-row-bg;
    }
  }

  .ant-table-expanded-row {
    .expanded-row-tbl {
      padding: 0 75px;
    }

    .ant-table.ant-table-small {
      box-shadow: none;
    }

    .ant-table-cell {
      background: @expanded-row-bg;
    }
  }

  a {
    color: black;
  }

  a:hover {
    color: @primary-color;
  }
}

.ant-table-content {
  border-radius: 0;
  // tr:last-child {
  //   border-bottom-right-radius: 6px;
  //   border-bottom-left-radius: 6px;
  // }
}

.ant-pro-table-column-setting-overlay {
  .ant-tree-treenode {
    &:hover {
      background-color: #e7f0f2;
    }

    .ant-tree-checkbox {
      top: 5px;
    }
  }
}

.ant-tree {
  .ant-tree-node-content-wrapper {
    &:hover {
      background-color: #e7f0f2;
    }
  }

  .ant-tree-switcher {
    width: 12px;
  }
}

.ant-pro-table-column-setting-list-item-option {
  span {
    .anticon {
      color: #0a5f7a !important;

      &:hover {
        color: #0a5f7a;
      }
    }
  }
}

// list styling
.ant-list-sm {
  .ant-list-item {
    padding: 2px 8px;
  }
}

/* table components */
.ant-table-filter-dropdown {
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  border-radius: 0;
  z-index: 1500 !important;
}

// Compatible with IE11
@media screen and(-ms-high-contrast: active), (-ms-high-contrast: none) {
  body .ant-design-pro > .ant-layout {
    min-height: 100vh;
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.ant-table-cell {
  .ant-tag {
    border-radius: 11px;
  }
}

/* row table css */
.box-shadow {
  box-shadow: 0 6px 10px rgb(0 0 0 / 8%), 0 0 6px rgb(0 0 0 / 5%);
}

.list-desc {
  margin-bottom: 10px;
  padding-left: 0;
  padding-right: 10px;
  // width: 90%;
  white-space: pre-line;
  word-break: break-all;
}

.full-width {
  width: 100%;
}

.profile-img-title {
  // font-size: 15px;
  text-align: center;
  margin-top: 10px;
}

.b-r10 {
  border-radius: 10px;
}

.b-r2 {
  border-radius: 2px;
}

.list-actions {
  opacity: 0;
}

.ant-table-row:hover {
  .list-actions {
    transition: 0.15s linear all;
    opacity: 1;
  }
}

.ant-pro-top-nav-header-main-left {
  min-width: 110px;
}

.ant-pro-top-nav-header-logo {
  min-width: 110px;
}

/* input component */
.ant-input {
  border-radius: 0;
}

/* popover component */
.ant-popover-inner {
  border-radius: 10px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
}

/* dropdown component */
.ant-dropdown-menu {
  border-radius: 2px;
}

.ant-modal-content {
  border-radius: 2px;
}

.ant-modal-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ant-tooltip-inner {
  border-radius: 10px;
}

.ant-table-filter-dropdown-btns {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ant-pro-basicLayout-content {
  position: relative;
  margin: 0;
}

.ant-pro-global-header {
  background-color: #0a5f7a;
  color: white;
}

.ant-btn {
  color: white;

  &.ant-btn-link[disabled] {
    color: rgb(0 0 0 / 25%);
  }

  &.ant-btn-link {
    color: @primary-color;
  }
}

.ant-modal-body {
  padding: 16px 24px;
}

.ant-col {
  .ant-form-item-label {
    padding: 0 0 8px;
  }
}

.ant-select-selection-item {
  border-radius: 15px !important;
}

.ant-dropdown {
  z-index: 1000;
}

.ant-form {
  .ant-row {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.ant-anchor-link-title {
  font-size: 14px;
}

.ant-table-tbody {
  tr.ant-table-row-selected {
    td {
      background: #e7f0f2;
    }

    &:hover {
      td {
        background: #e7f0f2;
      }
    }
  }
}

.ant-pro-list {
  .ant-pro-list-row-title {
    &:hover {
      color: #0a5f7a;
    }
  }
}

.ant-dropdown-menu-item-selected {
  background: #e7f0f2;
}

.ant-descriptions-bordered {
  .ant-descriptions-view {
    page-break-after: avoid;
    border: 1px solid #ddd;
  }

  .ant-descriptions-row {
    border-bottom: 1px solid #ddd;

    .ant-descriptions-item-label {
      page-break-after: avoid !important;
      border-right: 1px solid #ddd;
      padding: 12px 16px;
      width: 25%;

      &:last-child {
        border-right: 0;
      }
    }

    .ant-descriptions-item-content {
      border-right: 1px solid #ddd;
      padding: 8px 16px;

      &:last-child {
        border-right: 0;
      }
    }
  }
}

.selectiontext {
  font-weight: 500;
  font-size: 14px;
}

.ant-menu {
  &:not(.ant-menu-horizontal) {
    .ant-menu-item-selected {
      background: #e7f0f2;
    }
  }
}

.ant-layout.ant-layout-has-sider > .ant-layout-content {
  background: white;
}

.ant-image-img {
  border-radius: 100px;
}

.ant-image-mask {
  border-radius: 100px;
}

.ant-pro-list-no-split {
  .ant-list {
    .ant-list-item {
      border-bottom: 1px solid #d9d9d9;
    }
  }

  .ant-pro-card {
    .ant-pro-card-body {
      padding: 0;
    }
  }
}

.ant-pro-table-list-toolbar-container {
  padding-top: 5px;
}

#fifth {
  .ant-pro-descriptions {
    .ant-descriptions-row > td {
      .ant-pro-descriptions {
        .ant-descriptions-row > td {
          padding-bottom: 2px;
        }
      }
    }
  }
}

.index_right__WAARj {
  .index_search__X-HQj {
    padding: 0 8px;
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #e7f0f2;
}
