@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.header {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.headertag {
  border-radius: 10px;
  color: #0a5f7a;
  border: 1.3px solid #0a5f7a;
  background-color: #e6eff2;
  margin-bottom: 4px;
}

.modaltitle {
  padding: 4px 2px;
}

.beautifystyle {
  float: left !important;
}

.switch {
  z-index: 1200;
}
