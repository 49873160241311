@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

tr {
  page-break-inside: avoid;
}

th {
  font-weight: 400;
}

.no-break {
  page-break-before: always;
}
