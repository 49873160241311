@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.header {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.summary {
  font-size: 12px;
}

.antdescriptionstitle {
  font-size: 14px !important;
}

.table {
  overflow: auto;
  padding: '0px 40px';
}

.sktcontainer {
  padding: 5px 10px;
}

.sktcontainermain {
  padding: 5px;
}
