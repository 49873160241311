/* stylelint-disable selector-class-pattern */

@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.headerSearch {
  display: inline-flex;
  align-items: center;

  .input {
    width: 0;
    min-width: 0;
    overflow: hidden;
    background: transparent;
    border-radius: 2px;
    transition: width 0.3s, margin-left 0.3s;

    :global(.ant-select-selection) {
      background: transparent;
    }

    input {
      box-shadow: none !important;
    }

    &.show {
      width: 210px;
      margin-left: 8px;
    }
  }
}
