@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.endorsers {
  width: 100%;
  background: #f4f4f4 !important;
}

.iframe {
  border: none;
  height: 80vh;
}
