/* stylelint-disable no-descending-specificity */
@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

@pro-header-hover-bg: rgba(0, 0, 0, 0.025);

.menu {
  :global {
    .ant-dropdown-menu-item {
      min-width: 160px;
    }
  }

  border-radius: 2px !important; // TODO: check other way to do it

  .profile {
    :global(.ant-dropdown-menu-title-content) {
      display: flex;
      flex-direction: column;
    }

    span:nth-child(2) {
      margin-top: 8px;
    }
  }

  .signout {
    color: @primary-color;
  }
}

.dark {
  .action {
    &:hover {
      background: #252a3d;
    }

    &:global(.opened) {
      background: #252a3d;
    }
  }
}

.right {
  display: flex;
  float: right;
  margin-left: auto;
  height: 48px;
  overflow: hidden;

  button {
    height: inherit;
  }

  .action {
    display: flex;
    align-items: center;
    transition: all 0.3s;

    > span {
      vertical-align: middle;
    }

    &:hover {
      background: @pro-header-hover-bg;
    }

    &:global(.opened) {
      background: @pro-header-hover-bg;
    }
  }

  .search {
    padding: 0 12px;

    &:hover {
      background: transparent;
    }
  }

  .account {
    .avatar {
      color: @primary-color;
      vertical-align: top;
      margin: auto 8px;
      background: rgb(255 255 255 / 85%);
    }
  }
}

@media only screen and (max-width: @screen-md) {
  :global(.ant-divider-vertical) {
    vertical-align: unset;
  }

  .right {
    position: absolute;
    top: 0;
    right: 12px;

    .account {
      .avatar {
        margin-right: 8;
      }
    }
  }
}
