@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

@white-background: #fff;

.menu {
  height: 100%;
}

.white {
  background: @white-background;
}

.container {
  padding: 22px 24px;
  max-height: 100vh;
  overflow-y: auto;
}

.settings-container {
  padding: 0;
}

.settings-content-wrapper {
  padding: 0;
}

.profile-upload {
  margin-left: 5px;
}

.page-header {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.btnicon {
  color: @primary-color !important;
  font-size: 16px;
}

.imagemodal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.imagepreview {
  display: none;
}
