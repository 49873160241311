@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.header {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.modaltitle {
  padding: 4px 2px;
}

.beautifystyle {
  float: left !important;
}

.switch {
  z-index: 1200;
}
