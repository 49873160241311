@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.maincontainer {
  max-width: 100vw;
  margin: auto;
  display: grid;
  float: left;
  gap: 0.5rem;
}

.headertag {
  border-radius: 10px !important;
  color: #0a5f7a !important;
  border: 1.3px solid #0a5f7a !important;
  background-color: #e6eff2 !important;
  margin-bottom: 4px;
}

.header {
  display: flex;
  margin-bottom: 16px;
}

.radiobtn {
  padding: 0 15px;
}

@media (min-width: 800px) {
  .maincontainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

.filterscontainer {
  max-width: 100vw;
  margin: auto;
  display: grid;
  float: left;
  gap: 0.5rem;
}

@media (min-width: 100px) {
  .filterscontainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 590px) {
  .filterscontainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 775px) {
  .filterscontainer {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 935px) {
  .filterscontainer {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 1110px) {
  .filterscontainer {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (min-width: 1285px) {
  .filterscontainer {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media (min-width: 1460px) {
  .filterscontainer {
    grid-template-columns: repeat(8, 1fr);
  }
}
