@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.container {
  margin: 20px;
  background: #f4f4f4 !important;
}

.endorsers {
  width: 100%;
  background: #f4f4f4 !important;
}

.page-header {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.addconnection {
  color: #0a5f7a !important;
  border-color: #bcd530 !important;
  background: #bcd530 !important;
  text-shadow: 0 0 !important;
  box-shadow: 0 0 !important;
}

.headertext {
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 10px;
}

.deletebtnicon {
  color: #ff4d4f !important;
}

.br20 {
  border-radius: 20px !important;
}
