.container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 9999;
}

.spin {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255 255 255 90%);
  height: 80px;
  width: 80px;
  border-radius: 5px;
}

.fullopacity {
  background: rgba(255 255 255) !important;
}
