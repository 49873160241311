.g6-tooltip {
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  font-size: 12px;
  color: #545454;
  background-color: rgb(255 255 255 / 90%);
  padding: 10px 8px;
  box-shadow: rgb(174 174 174) 0 0 10px;
}

/* stylelint-disable-next-line selector-id-pattern */
#contextMenu {
  position: absolute;
  list-style-type: none;
  padding: 10px 8px;
  left: -150px;
  background-color: rgb(255 255 255 / 90%);
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  font-size: 12px;
  color: #545454;
}

/* stylelint-disable-next-line selector-id-pattern */
#contextMenu li {
  cursor: pointer;
  list-style: none;
  list-style-type: none;
  margin-left: 0;
}

/* stylelint-disable-next-line selector-id-pattern */
#contextMenu li:hover {
  color: #aaa;
}

.g6-minimap {
  height: 157px !important;
}

.g6-minimap-container {
  border: 1px solid #e2e2e2;
}

.g6-minimap-viewport {
  border: 2px solid rgb(25 128 255);
}

.fullscreen-enabled {
  background-color: white;
}
