@import (reference) '~antd/es/style/themes/default';
@import (reference) 'themes/main-variables.less';

.header {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.inputstyle {
  margin-top: 16px;
}
